<template>
  <div class="footbox_pc hidden-xs-only">
    <div class="foot_pc">
      <div>
        <el-image class="foot_logo" src="https://ejz-1317231021.cos.ap-beijing.myqcloud.com/home%2Flogo%402x.png" fit="contain" />
        <h3>关于我们</h3>
        <p><a href="#">电话：15726637144</a></p>
        <p><a href="#">地址：北京东燕郊尚街G5-3003</a></p>
        <p><a href="https://beian.miit.gov.cn/">备案号：冀ICP备2023008254号-1</a></p>
      </div>
      <div>
        <h3>案例展示</h3>
        <p><a href="#">APP项目</a></p>
        <p><a href="#">小程序项目</a></p>
        <p><a href="#">网站项目</a></p>
      </div>
      <div>
        <h3>服务领域</h3>
        <p><a href="#">APP开发</a></p>
        <p><a href="#">小程序开发</a></p>
        <p><a href="#">网站建设</a></p>
      </div>
      <div>
        <el-image class="foot-code" src="https://ejz-1317231021.cos.ap-beijing.myqcloud.com/home%2Fcode.jpg" fit="contain" />
        <el-image class="foot-code" src="https://ejz-1317231021.cos.ap-beijing.myqcloud.com/home%2Fcode.jpg" fit="contain" />
      </div>
    </div>
  </div>
  <div class="foot_phone hidden-sm-and-up">
    <el-image class="footphone_logo" src="https://ejz-1317231021.cos.ap-beijing.myqcloud.com/home%2Flogo%402x.png" fit="contain" />
    <div class="footphone_cont">
      <h3>关于我们</h3>
      <p class="telephone"><a href="#">电话：15726637144</a></p>
      <p class="site"><a href="#">地址：北京东燕郊尚街G5-3003</a></p>
      <p class="site"><a href="https://beian.miit.gov.cn/">备案号：冀ICP备2023008254号-1</a></p>
    </div>
    <div class="footphone_cont">
      <h3>案例展示</h3>
      <p class="footcont-p">
        <span><a href="#">小程序项目</a></span>
        <span><a href="#">APP项目</a></span>
        <span><a href="#">网站项目</a></span>
      </p>
    </div>
    <div class="footphone_cont">
      <h3>服务领域</h3>
      <p class="footcont-p">
        <span><a href="#">小程序项目</a></span>
        <span><a href="#">APP项目</a></span>
        <span><a href="#">网站项目</a></span>
      </p>
    </div>
    <div>
      <el-image
        class="footphone-code footphone-code1"
        src="https://ejz-1317231021.cos.ap-beijing.myqcloud.com/home%2Fcode.jpg"
        fit="contain"
      />
      <el-image class="footphone-code" src="https://ejz-1317231021.cos.ap-beijing.myqcloud.com/home%2Fcode.jpg" fit="contain" />
    </div>
  </div>
</template>

<script setup>

</script>

<style scoped>
.footbox_pc {
  width: 100%;
  height: 294px;
  padding-top: 94px;
  background: #26282b;
}
.foot_pc {
  max-width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-around;
}
.foot_logo {
  width: 152px;
  height: 66px;
}
.foot_pc h3 {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #f1f2f2;
  line-height: 16px;
  margin-bottom: 28px;
  margin-top: 15px;
}
.foot_pc p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 14px;
  margin-bottom: 24px;
}
.foot_pc p a {
  color: rgba(255, 255, 255, 0.6);
}
.foot-code {
  width: 163px;
  height: 163px;
  margin-left: 38px;
}

/* 移动端 */
.foot_phone {
  padding: 40px 0 0 0;
  width: 100%;
  height: 596px;
  background: #26282b;
}
.footphone_logo {
  width: 150px;
  height: 66px;
  margin-left: 20px;
}
.footphone_cont {
  margin-bottom: 28px;
}
.footphone_cont h3 {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #f1f2f2;
  line-height: 16px;
  margin-left: 28px;
  margin-bottom: 12px;
}
.footphone_cont a {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
  line-height: 44px;
}
.telephone,
.site {
  margin-left: 28px;
}
.footcont-p {
  display: flex;
  justify-content: space-around;
}
.footphone-code {
  width: 146px;
  height: 146px;
}
.footphone-code1 {
  margin-left: 28px;
  margin-right: 21px;
}
</style>