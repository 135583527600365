<template>
  <div class="headbox_pc hidden-xs-only">
    <div class="head_pc">
      <el-image class="logo" src="https://ejz-1317231021.cos.ap-beijing.myqcloud.com/home%2Flogo%402x.png" fit="contain" />
      <div class="nav">
        <div
          @click="goNav(i)"
          v-for="(item, i) in menuList"
          :key="i"
          :class="index == i ? 'active' : ''"
        >
          <i class="lf"></i><span>{{ item.name }}</span
          ><i class="rg"></i>
        </div>
      </div>
    </div>
  </div>
  <div class="headbox_phone hidden-sm-and-up">
    <div class="head_phone">
      <el-image class="logo" src="https://ejz-1317231021.cos.ap-beijing.myqcloud.com/home%2Flogo%402x.png" fit="contain" />
      <div class="bar" @click="drawerShow = !drawerShow"></div>
    </div>
    <div class="more">
      <el-drawer v-model="drawerShow" :with-header="false" size="50%">
        <div class="more_logo"></div>
        <div
          @click="goNav(i)"
          :class="index == i ? 'title active' : 'title'"
          v-for="(item, i) in menuList"
          :key="i"
        >
          {{ item.name }}
        </div>
        <div class="more_close" @click="drawerShow = !drawerShow"></div>
      </el-drawer>
    </div>
  </div>
</template>

<script setup>
import router from "@/router";
import { ref, watchEffect } from "vue";
let index = ref(0);
let drawerShow = ref(false);
let menuList = [
  { name: "首页", path: "/" },
  { name: "小程序开发", path: "/applet" },
  { name: "网站建设", path: "/website" },
  { name: "APP开发", path: "/app" },
  { name: "案例展示", path: "/case" },
  { name: "关于我们", path: "/about" },
];

function goNav(i) {
  index.value = i;
  router.push(menuList[i].path);
  drawerShow.value = false;
}

watchEffect(() => {
  router.getRoutes().map((item, i) => {
    if (item.path === router.currentRoute.value.path) {
      index.value = i;
    }
  });
});
</script>

<style scoped>
.headbox_pc {
  width: 100%;
  height: 80px;
  line-height: 80px;
  background: #0f1423;
}
.head_pc {
  max-width: 1200px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.head_pc .logo {
  width: 104px;
  height: 46px;
}
.nav {
  display: flex;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #a7aaad;
}
.nav div {
  display: inline-block;
  width: 96px;
  text-align: center;
  margin: 0 4px;
}
.active span {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  padding: 0 2px;
  vertical-align: middle;
}
.active .lf {
  display: inline-block;
  width: 10px;
  height: 20px;
  background-image: url(../../assets/images/home/导航选中／蓝／左@2x.png);
  background-size: 100% 100%;
  vertical-align: middle;
}
.active .rg {
  display: inline-block;
  width: 10px;
  height: 20px;
  background-image: url(../../assets/images/home/导航选中／蓝／右@2x.png);
  background-size: 100% 100%;
  vertical-align: middle;
}

/* 移动端 */
.headbox_phone {
  width: calc(100% - 30px);
  height: 44px;
  background: #26292b;
  padding: 0 16px 0 14px;
}
.head_phone {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.head_phone .logo {
  width: 89.41px;
  height: 40px;
}
.head_phone .bar {
  width: 24px;
  height: 24px;
  background-image: url(../../assets/images/home/icon／更多@2x.png);
  background-size: 24px 24px;
}
.more >>> .el-drawer {
  background-color: #26292b;
}
.more_logo {
  width: 89.41px;
  height: 40px;
  background-image: url(../../assets/images/home/logo@2x.png);
  background-size: 100% 100%;
  margin: 45px auto 0;
}
.title {
  height: 80px;
  line-height: 80px;
  text-align: center;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #eee;
}
.more_close {
  width: 40px;
  height: 40px;
  background-image: url(../../assets/images/home/icon／取消.png);
  background-size: 40px 40px;
  margin: auto;
}
</style>