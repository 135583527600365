import { createRouter, createWebHistory } from 'vue-router'


const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    meta: {
      title: '首页',
      content: {
        keywords: '廊坊燕郊小程序网站APP开发官网',
        description: '廊坊燕郊小程序网站APP开发官网',
      },
    }
  },
  {
    path: '/applet',
    name: 'applet',
    component: () => import('../views/AppletView.vue'),
    meta: {
      title: '小程序开发',
      content: {
        keywords: '廊坊燕郊小程序网站APP开发官网',
        description: '廊坊燕郊小程序网站APP开发官网',
      },
    }
  },
  {
    path: '/website',
    name: 'website',
    component: () => import('../views/WebsiteView.vue'),
    meta: {
      title: '网站建设',
      content: {
        keywords: '廊坊燕郊小程序网站APP开发官网',
        description: '廊坊燕郊小程序网站APP开发官网',
      },
    }
  },
  {
    path: '/app',
    name: 'app',
    component: () => import('../views/AppView.vue'),
    meta: {
      title: 'APP开发',
      content: {
        keywords: '廊坊燕郊小程序网站APP开发官网',
        description: '廊坊燕郊小程序网站APP开发官网',
      },
    }
  },
  {
    path: '/case',
    name: 'case',
    component: () => import('../views/CaseView.vue'),
    meta: {
      title: '案例展示',
      content: {
        keywords: '廊坊燕郊小程序网站APP开发官网',
        description: '廊坊燕郊小程序网站APP开发官网',
      },
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue'),
    meta: {
      title: '关于我们',
      content: {
        keywords: '廊坊燕郊小程序网站APP开发官网',
        description: '廊坊燕郊小程序网站APP开发官网',
      },
    }
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('../views/DetailView.vue'),
    meta: {
      title: '详情',
      content: {
        keywords: '廊坊燕郊小程序网站APP开发官网',
        description: '廊坊燕郊小程序网站APP开发官网',
      },
    }
  },
]

const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(),
  routes
})
router.beforeEach((to) => {
  if (to.meta.content) {
    let head = document.getElementsByTagName('head');
    let meta = document.createElement('meta');
    document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
    meta.content = to.meta.content;
    head[0].appendChild(meta);
  }
  if (to.meta.title) {
    window.document.title = to.meta.title;
  }
})

export default router
